//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TopFooterForm from '~/components/TopFooterForm.vue';
import BottomFooter from '~/components/BottomFooter.vue';
import TopFooterLinks from '~/components/TopFooterLinks.vue';

export default {
  name: 'Footer',
  components: {
    BottomFooter,
    TopFooterForm,
    TopFooterLinks,
  },
};
