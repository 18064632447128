//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import StoryblokClient from 'storyblok-js-client';
import { CMSService } from '~/services/storyBlok/cmsService';

export default {
  name: 'TopFooterLinks',
  data() {
    return {
      footerData: {},
      storyRespId: '',
    };
  },
  methods: {
    getLinkName(name) {
      return `${this.$options.name}_${name}`;
    },
  },
  computed: {
    footerContent() {
      const Storyblok = new StoryblokClient({ accessToken: process.env.NUXT_ENV_STORYBLOK });
      const hoursCopy = this.footerData[0]?.footer[0]?.hoursCopy;

      return hoursCopy ? Storyblok.richTextResolver.render(hoursCopy) : '';
    },
    editableFooterContent() {
      return this.footerData[0]?.footer[0] ?? '';
    },
    ...mapGetters([
      'isInStoryblok',
    ]),
  },
  async fetch() {
    const storyBlok = new CMSService({ cmsService: this.$storyapi });
    const { data } = await storyBlok.get('data/footer');

    this.footerData = data.story?.content?.body || [];
    this.storyRespId = data?.story?.id;
  },
  mounted() {
    if (this.isInStoryblok) {
      this.$storybridge(
        () => {
          const { StoryblokBridge } = window;
          if (StoryblokBridge != null) {
            const storyblokInstance = new StoryblokBridge();
            storyblokInstance.on(['input', 'published', 'change'], (event) => {
              if (event.action === 'input') {
                if (event.story.id === this.storyRespId) {
                  this.footerData = event.story.content.body;
                }
              } else {
                window.location.reload();
              }
            });
          }
        },
        (error) => {
          console.error('ERROR', error);
        },
      );
    }
  },
};
